<template>
  <div class="login flex j-center a-center column">
    <div class="tb" />
    <div class="content flex j-center a-center">
      <div class="left" />
      <div class="right flex j-center a-center">
        <div class="content_right">
          <div class="top theme-font f25 flex a-center p-lr-20">
            用户登录
            <span class="no-weight f12 p-top-10 p-left-10" style="color:#ccc">User Login</span>
          </div>
          <div class="user  p-lr-40 m-top-45">
            <div class="flex a-center min-border-bottom">
              <span class="iconfont icon-user weight" />
              <el-input v-model="form.UserName" type="text" clearable class="rule-input" placeholder="请输入用户名" style="border:none;padding:0" />
            </div>
          </div>
          <div class="psd  p-lr-40 m-top-20">
            <div class="flex a-center min-border-bottom">
              <span class="iconfont icon-key weight" />
              <el-input v-model="form.PWD" show-password type="text" clearable class="rule-input" placeholder="请输入密码" style="border:none" />
            </div>
          </div>
          <div class="psd  p-lr-40 m-top-20">
            <div class="flex a-center min-border-bottom">
              <span class="el-icon-star-off weight" />
              <el-input v-model="form.ValidateCode" :maxlength="4" type="text" clearable class="rule-input" placeholder="请输入验证码" style="border:none" />
              <!-- <div class="code_img" :style="{backgroundImage: `url(${code_img})`}" /> -->
              <div v-if="code_img !==null && code_img.length > 0" class="cursor">
                <img class="code_img cursor" :src="code_img" @click="changeImg">
              </div>
            </div>
          </div>
          <div class="theme-color f12 m-top-20 flex j-end  p-lr-40  select_none">
            <span class="cursor" @click="() => $router.push({name:'FindPassword'})">忘记密码</span>

            <span class="cursor m-left-10" @click="() => $router.push({name:'RetrieveNumber'})">账号找回</span>
          </div>

          <div class="btn flex j-center m-top-50 select_none cursor">
            <div
              v-loading="isLogin"
              class="_btn"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(34, 91, 199, 0.8)"
              @click.stop="login"
            >
              登  录
            </div>
            <div
              class="_btn m-left-10"
              @click="settlein"
            >
              企业入驻
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tb foot f12 flex j-center a-center column" style="color:#646464">
      <span class="p-bottom-5">
        Copyright © 2021 上海秸瑞信息科技有限公司 版权所有 沪ICP备19046069号-1
      </span>
      <span> 互联网药品信息服务资格证书编号：(沪)-经营性-2020-0019      ICP增值电性业务许可证书编号：沪B2-20200588</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        UserName: '',
        PWD: '',
        ValidateCode: '',
        Guid: ''
      },
      code_img: '',
      isLogin: false
    }
  },
  mounted() {
    this.GetValidateCodeImg()
    window.addEventListener('keydown', this.keyDown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDown)
  },
  methods: {
    // 企业入驻
    settlein() {
      console.log('dqa')
      this.$router.push({
        name: 'settleIn'
      })
    },
    changeImg() {
      this.GetValidateCodeImg()
    },
    GetValidateCodeImg() {
      this.$api.GetValidateCodeImg().then(res => {
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        this.code_img = `data: image/jpg; base64, ${res.Message.Bytes}`
        this.form.Guid = res.Message.Guid
      })
    },
    // 回车事件
    keyDown(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    login() {
      console.log(this.form)
      if (!this.form.UserName || !this.form.PWD || !this.form.ValidateCode) return this.$message.info('内容不能为空')
      this.isLogin = true
      this.$api.DoPassPortLogin(this.form).then(res => {
        this.isLogin = false
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        console.log(res)
        localStorage.setItem('token', res.Message._token)
        this.setCookie('token', res.Message._token)
        // document.cookie = '_ticket' + '=' + res.Message._ticket + ';'
        this.setCookie('_ticket', res.Message._ticket)
        window.removeEventListener('keydown', this.keyDown)
        this.$router.push('/')
        // window.removeEventListener('keydown', this.keyDown)
      }).catch(err => {
        this.isLogin = false
        return this.$message.error(err)
      })
    },
    setCookie(name, value) {
      var Days = 1
      var exp = new Date()
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
      document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString()
    }
  }
}
</script>

<style lang="scss">
@media  only screen and (max-width: 1300px) {
  .content_right {
      margin-right: 100px;
  }
  .left {
     background-size: 80%;
  }
}
@media screen  and (max-height: 740px) {
    .login {
       overflow: auto !important;
    }
    // .left {
    //   background-size: 100%;
    // }
}
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // min-width: 1000px;
  // min-height: 1000px;
  // // min-width: 1600px;
  // overflow: hidden;
  // background-image: url("../../assets/bgimg.png");

  .content {
    width: 100%;
    flex: 1;
    min-height: 600px;
    background-color: rgb(34, 91, 199);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .left {
    width: 850px;
    height: 90%;
    background-image: url('../../static/bg.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }
  .right {
    // height: 100%;
    .content_right {
      width: 400px;
      border-radius: 10px;
      height: 450px;
      background-color: #fff;
      .top {
        width: 100%;
        height: 50px;
        position: relative;
        &::after{
          content: '';
          width: 10px;
          height:50%;
          background-color:  #006fda;
          position: absolute;
          left: 0;
          top: 25%;
        }
      }
      .btn {
        width: 100%;
        height: 40px;
        ._btn{
        width: 150px;
        background-color:  rgb(62, 118, 237);
        color: #fff;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        }
      }
      .code_img{
        width: 100px;
        height: 100%;
        background-size: cover;
      }
    }
  }

  .tb {
    width: 100%;
    height: 80px;
    min-height: 80px;
    background-color: rgb(245, 246, 250);
  }
}

 .rule-input {
    .el-input__inner {
      border: 0;
    }
  }

</style>
